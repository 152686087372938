import ngmiLogo from "../../images/logo.svg";
import {useState} from "react";

const TopBar = (props: any) => {
    let [menuOpen, setMenuOpen] = useState<boolean>(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        document.body.classList.toggle('no-scroll');
    }

    return <>
        <nav className="ngmi-top-bar d-flex justify-content-between align-items-center">
            <img className="ngmi-logo ngmi-logo--header" src={ngmiLogo} alt=""/>

            <button className={"ngmi-hamburger-menu" + (menuOpen ? ' active' : '')} onClick={e => toggleMenu()}>
                <span className="ngmi-hamburger-menu__bar"/>
                <span className="ngmi-hamburger-menu__bar"/>
            </button>
        </nav>

        <nav className={"ngmi-navigation d-flex justify-content-center align-items-center" + (menuOpen ? ' active' : '')}>
            <ul className="ngmi-menu">
                <li className="ngmi-menu__item">
                    <a onClick={e => toggleMenu()} href="#club">CLUB</a>
                </li>
                <li className="ngmi-menu__item">
                    <a onClick={e => toggleMenu()} href="#about">ABOUT</a>
                </li>
                <li className="ngmi-menu__item">
                    <a onClick={e => toggleMenu()} href="#faq">FAQ</a>
                </li>
            </ul>
        </nav>
    </>
};

export default TopBar;
