import {useState, useEffect} from "react";
import scrollIcon from "../../../images/scroll-icon.svg";

const PASSWORD = '69degen';

const MintSection = (props: any) => {
    let [mintBoxHidden, setMintBoxHidden] = useState<boolean>(true);

    useEffect(() => {
        if (document.cookie.split('; ').find(entry => entry.startsWith('passwordVerified'))) {
            setMintBoxHidden(false);
        }
    }, []);

    const checkPassword = (event: any) => {
        setMintBoxHidden(event.target.value !== PASSWORD);
        let now = new Date();
        let expireTime = now.getTime() + 14 * 24 * 60 * 60 * 1000;
        now.setTime(expireTime);
        document.cookie = "passwordVerified=true; expires=" + now.toUTCString() + "; SameSite=None; Secure";
    }

    return (
        <section className="ngmi-mint ngmi-section ngmi-section--mint-section d-flex justify-content-center">
            <div className="mint-container">
                <h3 className="ngmi-mint__subheading text-center">
                    Listen, nobody can stop you from doing it but do NOT mint these NFTs
                </h3>

                <h1 className="ngmi-mint__heading text-center">
                    If you mint this you’re NGMI
                </h1>

                <div className={'ngmi-login text-center' + (mintBoxHidden ? '' : ' d-none')}>
                    <label className="ngmi-login__label">
                        <span className="ngmi-login__label__text">
                            ENTER PASSWORD
                        </span>
                        <input type="password" className="ngmi-login__input" autoFocus={true} autoComplete="off" onChange={checkPassword}/>
                    </label>

                    <div className="monolif-typing d-flex justify-content-center align-items-center">
                        <div className="monolif-typing__dots d-flex justify-content-center">
                            <div className="monolif-typing__middle-dot"/>
                        </div>
                        <div className="monolif-typing__text">
                            <span className="monolif-typing__text__heavy">Monoliff</span> is typing…
                        </div>
                    </div>
                </div>

                <div className={'mintbox-container d-flex justify-content-center align-items-center' + (mintBoxHidden ? ' d-none' : '')}>
                    {props.children}
                </div>

                <div className="text-center">
                    <div className="scroll-down-button">
                        <img src={scrollIcon} className='scroll-down-button__icon' alt=""/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MintSection;
