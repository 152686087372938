const NFTS = [
    require('../../../images/club-images/wagmi-1-v2.jpg').default,
    require('../../../images/club-images/wagmi-2-v2.jpg').default,
    require('../../../images/club-images/wagmi-3-v3.jpg').default,
    require('../../../images/club-images/wagmi-4-v2.jpg').default,
];

const NftClubSection = (props: any) =>
    <section id="club" className="ngmi-section ngmi-section--nft-club">
        <h3 className="ngmi-section__heading mb-5 text-center">
            DON’T MINT. YOU COULD BE PART OF<br/>
            THE WAGMI COUNTRY CLUB
        </h3>

        <ul className="ngmi-nft-list d-flex justify-content-between align-items-center">
            {
                NFTS.map(
                    (url, index) =>
                        <li key={index} className="ngmi-nft-list__item col-12 col-md-3">
                            <img className="ngmi-nft" src={url} alt=""/>
                        </li>
                )
            }
        </ul>
    </section>
;

export default NftClubSection;
