const EMOJI_LIST = ['1F603', '1F603', '1F642', '1F643', '1F609', '1F60A', '1F607', '1F970', '1F60D', '1F929', '1F972', '1F60B', '1F92A', '1F917', '1F92B', '1F914', '1F910', '1F92F', '1F920', '1F973', '1F978', '1F60E', '1F9D0', '1F608', '1F47B', '1F916', '1F648', '1F649', '1F64A', '2764', '2764', '1F9E1', '1F49B', '1F49A', '1F49C', '1F5A4', '1F90D', '1F4AF', '1F4A5', '1F4A6', '1F4A3', '1F44B', '1F44C', '1F90C', '1F91F', '1F918', '1F446', '261D', '1F44D', '1F44A', '1F44F', '1F64C', '1F91D', '1F64F', '1F4AA', '1F9BE', '1F9E0', '1F440', '1F435', '1F412', '1F98D', '1FAB6', '1F433', '1F34C', '1F6A8', '1F680', '1F6CE', '1F315', '1F319', '2B50', '26A1', '1F525', '1F30A', '1F9E8', '1F389', '1F38A', '1F3C6', '1F3C5', '1F947', '1F3AF', '2665', '1F576', '1F451', '1F48E', '1F514', '1F4C8', '2B06', '1F51D', '203C', '2757', '2705', '2714'];
const BEGINNING_OF_TIME_IN_HOURS = 459610;
const REACTION_UPDATE_FREQUENCY = 1000 * 60 * 60; // hourly

const getRandomNumber = (min = 0, max = 15) => Math.floor(Math.random() * (max - min) + min);

const generateReactions = (number: number, range = 10): Array<{icon: string, count: number}> => {
    let reactionIcons = EMOJI_LIST.slice();
    let reactions = [];

    for (let i = 0; i < number; i++) {
        let newReactionIndex = getRandomNumber(0, reactionIcons.length - 1);
        let min = Math.floor((new Date()).getTime() / REACTION_UPDATE_FREQUENCY) % BEGINNING_OF_TIME_IN_HOURS;

        reactions.push({
            icon: reactionIcons[newReactionIndex],
            count: getRandomNumber((min - 1) * range, (min - 1) * range + range),
        });

        reactionIcons.splice(newReactionIndex, 1);
    }

    return reactions;
};

const FAQS = [
    {
        question: `WHAT DOES THIS COLLECTION CONSIST OF?`,
        answer: `NO WAY TO KNOW UNLESS YOU MINT A PIECE. BUT IF YOU DO, YOU’RE NGMI. ALL WE CAN TELL YOU IS IT’S VERY META AND IT CONTAINS REFERENCES TO SOLANA NFT HISTORY.`,
        reactions: generateReactions(10),
    },
    {
        question: `SHOULD I MINT ONE?`,
        answer: `ABSOLUTELY NOT. BUT IF YOU HAVE TO PLEASE KNOW THAT THESE NFTS ARE RESERVED FOR FOR DEGENS, MONKEYS AND THUGS WHO HAVE BEEN HERE FOR A WHILE. IT’S NOT FOR EVERYBODY AND MOST LIKELY IT’S NOT FOR YOU.`,
        reactions: generateReactions(13, 7),
    },
    {
        question: `WHY IS THIS A FREE MINT?`,
        answer: `IT’S NOT FOR EVERYBODY. THIS MINT IS FOR PEOPLE WHO HAVE BEEN HERE A WHILE. IF YOU’RE A NEWBIE AND YOU MINT YOU ARE CLEARLY NGMI. ON THE OTHER HAND, IF WE ALL DECIDE THIS IS VALUABLE IT WILL BE INCREDIBLY VALUABLE. THIS COULD BE A PIECE OF SOLANA NFT HISTORY. HMMMM….`,
        reactions: generateReactions(9),
    },
    {
        question: `IS THIS WORTH MINTING?`,
        answer: `NO. REALLY THINK ABOUT IT. IT’S A WORTHLESS JPG ON A BLOCKCHAIN THAT DOESN’T WORK ALL THE TIME. LIKE THE MUCH SUPERIOR ETHERIUM. WE ALL LOVE PAYING GAS FEES TO MINT VALUABLE JPGS OVER THERE IN 20 MINUTES OR SO. `,
        reactions: generateReactions(15, 9),
    },
    {
        question: `I’VE BEEN AROUND THE BLOCKCHAIN SINCE DEFI SUMMER, I CAN DEFI PRETTY HARD, I HAVE A LOT OF SOL BUT I’M NEW TO NTFS. CAN I MINT?`,
        answer: `NO. AS YOU’VE BEEN TOLD, THIS IS NOT FOR YOU. THINK ABOUT IT. IT’S LIKE LOSING MORE THAN 0.01 SOL PER DAY FOR A WHOLE YEAR. WOULD YOU DEFINE FOR NEGATIVE YIELD? FUCK THAT, IT WOULD BE IRRESPONSIBLE. ON THE OTHER HAND, IT’S A ONE OF A KIND NFT DRENCHED IN META THAT COULD BE VALUABLE ONE DAY.`,
        reactions: generateReactions(12, 6),
    },
    {
        question: `I’VE BEEN AROUND THE BLOCKCHAIN SINCE DEFI SUMMER, I CAN DEFI PRETTY HARD, I HAVE A LOT OF SOL BUT I’M NEW TO NTFS. CAN I MINT?`,
        answer: `NO. AS YOU’VE BEEN TOLD, THIS IS NOT FOR YOU. THINK ABOUT IT. IT’S LIKE LOSING MORE THAN 0.01 SOL PER DAY FOR A WHOLE YEAR. WOULD YOU DEFINE FOR NEGATIVE YIELD? FUCK THAT, IT WOULD BE IRRESPONSIBLE. ON THE OTHER HAND, IT’S A ONE OF A KIND NFT DRENCHED IN META THAT COULD BE VALUABLE ONE DAY.`,
        reactions: generateReactions(6, 10),
    },
    {
        question: `OK. I’M SOLD. I WANT TO MINT ONE.`,
        answer: `YOU’RE NGMI - NO REAL WORLD VALUE AND IT’S NOT AN UTILITY TOKEN. IT’S JUST A JPG COSTING AS MUCH AS A NICE TV THAT CAN BE APPRECIATED BY A SMALL NUMBER OF PEOPLE. IF YOU’RE NOT ONE OF THOSE PEOPLE THEN SELL ME YOUR SOL AT 3$ AND GO FUCK OFF.`,
        reactions: generateReactions(5, 6),
    },
    {
        question: `OOOOPS! I’VE ACCIDENTALLY MINTED HARD. AM I NGMI?`,
        answer: `YUP! YOU’VE GOT THAT RIGHT. IF YOU’VE SPENT THAT KIND OF SOL ON A PROJECT THAT SPECIFICALLY TOLD YOU NOT TO MINT, YOU ARE NGMI. OR YOU’LL BE PART OF SOLANA NFT HISTORY. TIME WILL TELL.`,
        reactions: generateReactions(10, 5),
    },
    {
        question: `OMG! I’VE GOT TO GET RID OF THEM, WHAT CAN I DO? `,
        answer: `YOU CAN SELL IT ON THE MARKET BUT NOBODY WILL BUY IT. THERE WILL BE AN ANNOUNCEMENT SOON ABOUT WHICH MARKETS WILL HOST THIS VALUED COLLECTION.`,
        reactions: generateReactions(6, 8),
    },
    {
        question: `CAN I SHOW THIS THING TO MY IRL FRIENDS?`,
        answer: `NO, YOU ABSOLUTELY CAN NOT!!! YOU WILL BE RIDICULED, THEY WILL LAUGH AT YOU, THEY WILL NOT UNDERSTAND. THIS IS WHY WE’VE TOLD YOU NOT TO MINT. UNLESS YOU HAVE CT FRIENDS WHO HAVE PICTURES OF APES, MONKEYS, AURORIANS OR THUGZ YOU SHOULD NOT SHOW THIS TO ANYONE. KEEP IT IN YOUR WALLET.`,
        reactions: generateReactions(7, 7),
    },
    {
        question: `WILL YOU MAKE A DAO?`,
        answer: `WE HAVE ENOUGH DAOS. NO NEED FOR MORE DAOS. IF WE WERE TO FORM A DAO IT WOULD BE FOR PEOPLE WHO WANT TO DEMOTIVATE EACH OTHER. LIKE IN GROUP THERAPY. WOULD YOU LIKE US TO MAKE THIS? MAYBE YOU’RE ONTO SOMETHING HERE...`,
        reactions: generateReactions(9, 5),
    },
    {
        question: `DO YOU HAVE A ROADMAP?`,
        answer: `SURE, WE HAVE A ROADMAP. IT’S BASED ON SLOW AND STEADY DEMOTIVATION AND A HEALTHY DAILY DOSE OF NGMI. MAYBE WE’LL AIRDROP ALL HOLDERS FROM TIME TO TIME NFTS THAT REMIND THEM THEY ARE NGMI.`,
        reactions: generateReactions(10, 9),
    },
    {
        question: `WHAT'S THE VISION HERE?`,
        answer: `NO VISION, NO PERSPECTIVE, NO HOPE, YOU’RE NGMI. UNLESS WE START AIRDROPPING YOU DEMOTIVATING NFTS MADE BY DEMOTIVATING ARTISTS. AUDIO-VIDEO WE’LL MAKE SURE ALL YOUR SENSES ARE TICKLED.`,
        reactions: generateReactions(6, 15),
    },
    {
        question: `WAIT, IS THIS A SCAM? WILL I BE RUGGED?`,
        answer: `NO, THIS IS NOT A SCAM, IT’S JUST SOMETHING YOU SHOULDN’T DO. IT’S AS SAFE AS ANY MINT, WE’RE USING CM2. BUT IT’S JUST SO FULL SEND META YOU PROBABLY WOULDN'T GET IT. BUT IF YOU DO, YOU CAN BE PART OF THIS.`,
        reactions: generateReactions(8, 4),
    },
    {
        question: `OK. I HAVE TO BE PART OF THIS. WHAT DO I DO?`,
        answer: `IF YOU HAVE TO MINT, MINT HARD ON THE ONLY BUTTON ON OUR WEBSITE. THIS IS THE WAY. GLAD TO HAVE YOU ABOARD AND HERE’S A LITTLE PIECE OF SOLANA HISTORY META.`,
        reactions: generateReactions(7, 11),
    },
    {
        question: `WILL I BE CONSIDERED COOL IF I MINT THIS?`,
        answer: `YOU’RE DAMN RIGHT YOU WILL.`,
        reactions: generateReactions(4, 10),
    },
    {
        question: `HELP! THERE’S A PASSWORD PROTECTING THE MINT BUTTON!`,
        answer: `YES, IT’S BEEN WRITTEN DOWN ON A POST IT SOMEWHERE ON AN OLD MACINTOSH. CAN’T REMEMBER IT RIGHT NOW BUT IF YOU FIND IT DON’T SHARE IT WITH ANYONE, ESPECIALLY ON CT.`,
        reactions: generateReactions(10, 5),
    },
    {
        question: `HOW DO I CONNECT MY WALLET, WHICH WALLET DO I USE, WHERE DO I GET SOL, WHAT IS SOL, IS THIS A SCAM?`,
        answer: `OK. GTFO. YOU SHOULD NOT BE DOING THIS. YOU’RE NGMI ALREADY. YOU DON’T NEED THIS.`,
        reactions: generateReactions(6, 10),
    },
    {
        question: `WAIT, I’M IMPORTANT CAN I GET ON THE PRESALE WHITELIST?`,
        answer: `NO. NO ONE HERE IS IMPORTANT. NOT EVEN US. NO PRESALE WHITELIST, STEALTH LAUNCH IN 24 HOURS. OR MAYBE 2 HOURS. FUCK IT. DON’T TELL ANYONE YOU’RE HERE OR THEY’LL MINT AHEAD OF YOU. FILL BEFORE YOU SHILL, THAT’S WHAT THEY SAY, RIGHT?`,
        reactions: generateReactions(5, 6),
    },
    {
        question: `HEY, WHAT DO THESE PICTURES MEAN?`,
        answer: `PICTURES ARE VISUAL REPRESENTATIONS OF IDEAS. THEY ARE MEANT TO CONVEY INFORMATION AND PEOPLE SAY THEY SPEAK LOUDER THAN 1000 WORDS. NOW YOU KNOW.`,
        reactions: generateReactions(9, 3),
    },
    {
        question: `WHY ARE THEY SHITTY PIXELATED REPRESENTATIONS OF AWESOME IDEAS?`,
        answer: `THAT’S EXACTLY WHAT WE WANTED TO DO! WE WANTED TO CONVEY THE IDEA THAT MOST NFTS LOOK SHITTY BUT HAVE HUGE VALUE TO THEIR HOLDERS THROUGH EMOTIONAL CONNECTION, ESPECIALLY IF THEY MINTED ONE. ALMOST LIKE CHILDBIRTH, GO ASK MOTHERS. THE WORLD LOOKS WITH DISGUST ON NFTS BUT WE LOVE THEM FOR WHAT THEY ARE. OR THEY’RE JUST A BUNCH OF LIMITED EDITION, ONE OF A KIND, HAND CRAFTED, SHITTY PIXELATED PICTURES THAT ARE GOING TO 0. WE TOLD YOU NOT TO MINT THEM.`,
        reactions: generateReactions(5, 9),
    },
    {
        question: `DID YOU CHOOSE THIS PIXELATION ON PURPOSE?`,
        answer: `YES, IT’S ON PURPOSE. WE’VE EXPERIMENTED WITH 69 DIFFERENT LEVELS OF PIXELATION. WE CAME TO THE CONCLUSION THAT THE MOST UNNERVING LEVEL OF PIXELATION IS THIS ONE. NOT PIXELATED LIKE THUGBIRDZ AND MONKES, NOT SMOOTH HIRES 3D GRAPHICS LIKE THE APES BUT SOMEWHERE IN THE MIDDLE. WE WENT STRAIGHT FOR THE ‘87 LEISURE SUIT LARRY LEVEL OF PIXELATION. WE FEEL THAT FOR A PROJECT THAT IS NGMI WE HAD TO GET INSPIRATION FROM ONE OF THE  WORST GAMES IN HISTORY. BY THE WAY, THE GRAPHICS OF THAT GAME AGED LIKE FINE WINE. CRAPPY WAS OUR GOAL LINE FOR ARTISTIC DIRECTION. WE MADE IT HAPPEN DIDN’T WE? DON’T FUCKIN’ MINT IF YOU DON’T LIKE THE ART.`,
        reactions: generateReactions(7, 9),
    },
    {
        question: `WAIT, WHO ARE YOU? WHO IS “WE”? ARE YOU DEVS?`,
        answer: `WE ARE IN NO WAY QUALIFIED TO DO WHAT WE DO. WE HAVE NO TRAINING, NO EXPERIENCE AND NO PERSPECTIVE. BUT WE HAVE FAITH AND VISION. WE’VE MINTED PRETTY HARD ALL THE SOLANA BLUE CHIPS BACK IN THE DAY AND THEN ALL THE SHIT_10K_PFPS AND NOW WE FEEL THE NEED TO MAKE A REALLY META NFT PROJECT AS AN ARTISTIC EXPERIMENT THAT, WE HOPE, CAPTURES THE VIBE AND PIECES OF HISTORY REPRESENTED THROUGH PARTICULARLY SHITTY GRAPHICS, AS YOU DO IN NFTS.`,
        reactions: generateReactions(8, 15),
    },
];

export default FAQS;
