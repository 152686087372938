import {useState} from "react";
import FAQS from "../faq-data";

const FaqSection = (props: any) => {
    let [faqs, updateFaqs] = useState(FAQS.slice());

    const incrementReaction = (faqIndex: number, reactionIndex: number) => {
        faqs[faqIndex].reactions[reactionIndex].count++;
        updateFaqs(faqs.slice());
    };

    return <section id="faq" className="ngmi-faq ngmi-section ngmi-section--faq">
        <h3 className="ngmi-section__heading mb-5 text-center">
            FAQs
        </h3>
        <div className="d-flex flex-column justify-content-center align-items-center">
            {
                faqs.map(
                    (faq: {question: string, answer: string, reactions: Array<{icon:string, count: number}>}, faqIndex: number) =>
                        <article key={faqIndex} className="ngmi-faq-box">
                            <h3 className="ngmi-faq-box__question">
                                {faq.question}
                            </h3>
                            <p className="ngmi-faq-box__answer">
                                {faq.answer}
                            </p>
                            <div className="ngmi-faq-box__reactions d-flex flex-wrap justify-content-start align-items-center">
                                {
                                    faq.reactions.map(
                                        (reaction: {icon: string, count: number}, reactionIndex: number) =>
                                            <button
                                                key={reactionIndex}
                                                className="ngmi-faq-box__reaction d-flex justify-content-center align-items-center"
                                                onClick={() => incrementReaction(faqIndex, reactionIndex)}
                                            >
                                                <span className="ngmi-faq-box__reaction__icon" dangerouslySetInnerHTML={{__html: `&#x${reaction.icon};`}}/>
                                                <span className="ngmi-faq-box__reaction__count">
                                                    {reaction.count}
                                                </span>
                                            </button>
                                    )
                                }
                            </div>
                        </article>
                )
            }
        </div>
    </section>
};

export default FaqSection;
