import * as anchor from "@project-serum/anchor";

import TopBar from "./TopBar";
import NftClubSection from "./Sections/NftClubSection";
import AboutSection from "./Sections/AboutSection";
import FaqSection from "./Sections/FaqSection";
import Footer from "./Footer";
import MintSection from "./Sections/MintSection";
import MintBox from "../MintBox/MintBox";

export interface HomeProps {
    candyMachineId: anchor.web3.PublicKey;
    config: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    startDate: number;
    treasury: anchor.web3.PublicKey;
    txTimeout: number;
}

const Home = (props: HomeProps) => {
    return (
        <main>
            <TopBar/>

            <MintSection>
                <MintBox {...props}/>
            </MintSection>

            <NftClubSection/>

            <AboutSection/>

            <FaqSection/>

            <Footer/>
        </main>
    );
};

export default Home;
