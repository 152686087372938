import ngmiLogo from "../../images/logo.svg";
import twitterIcon from "../../images/twitter-icon.svg";

const Footer = (props: any) =>
    <footer className="ngmi-footer">
        <img className="ngmi-logo ngmi-logo--footer" src={ngmiLogo} alt=""/>

        <a href="/" target="_blank">
            <img className="ngmi-twitter" src={twitterIcon} alt=""/>
        </a>

        <p className="ngmi-copyright-text">
            &copy; All rights reserved 2022.
        </p>
    </footer>
;

export default Footer;
