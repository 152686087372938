const AboutSection = (props: any) =>
    <section id="about" className="ngmi-about ngmi-section ngmi-section--about">
        <div className="ngmi-about__inner">
            <h3 className="ngmi-section__heading text-center mb-5">
                WHAT’S THIS COLLECTION ABOUT?
            </h3>

            <h4 className="ngmi-about__meta-heading text-center d-flex justify-content-center align-items-center">
                IT’S NFT META
            </h4>

            <h3 className="ngmi-section__heading text-center mb-5">
                WHAT THIS COLLECTION ISN’T ABOUT?
            </h3>

            <p className="ngmi-about__fact text-center">IT’S NOT ART.</p>
            <p className="ngmi-about__fact text-center">IT’S NOT CHEAP.</p>
            <p className="ngmi-about__fact text-center">IT HAS NO DISCORD.</p>
            <p className="ngmi-about__fact text-center">IT’S NOT FOR FLIPPERS.</p>
            <p className="ngmi-about__fact text-center">IT’S NOT THAT PIXELATED.</p>
            <p className="ngmi-about__fact text-center">IT’S REALLY NOT FOR EVERYBODY.</p>
            <p className="ngmi-about__fact text-center">IT’S NOT A 10K/5K/3.333K PFP PROJECT.</p>
            <p className="ngmi-about__fact text-center">IT’S NOT POORLY MADE OR A LOW EFFORT ETH COPY.</p>
            <p className="ngmi-about__fact text-center">IT’S NOT GENERATIVE BASED ON TX HASHES, IT’S HANDMADE.</p>
        </div>
        <div className="ngmi-about__bottom"/>
    </section>
;

export default AboutSection;
